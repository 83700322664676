enum Connections {
	ADOBE_AUDIENCE_MANAGER = 'Adobe_Audience_Mgr',
	APP_NEXUS = 'AppNexus',
	AWS_S3 = 'AWS_S3',
	DATA_VISION = 'DataVision',
	ELOQUA = 'Eloqua',
	FACEBOOK = 'Facebook',
	GOOGLE_ADS = 'GoogleAds',
	GOOGLE_DV = 'Google_Display_N_Video_360',
	LINKEDIN = 'LinkedIn',
	MARKETO = 'Marketo',
	MEDIA_MATH = 'MediaMath',
	OUTREACH = 'Outreach',
	PARDOT = 'Pardot',
	SALESFORCE = 'Salesforce',
	Salesforce_Marketing_Cloud = 'SalesforceMarketingCloud',
	SALESLOFT = 'SalesLoft',
	TRADE_DESK = 'TradeDesk',
	VENDEMORE = 'Vendemore',
	VERIZON_MEDIA = 'Verizon_Media',
	GenericDSP = 'GenericDsp',
	Hub_Spot = 'HubSpot',
	/* TODO: Verify all end to end instances of this enum. */
	Microsoft_Dynamics365 = 'Dynamics365',
}

enum Tiles {
	AUDIENCE = 'Audience',
	CONNECTION_SETTINGS = 'Connection_Settings',
	FIELD_MAPPINGS = 'Field_Mappings',
	FILTER_SETTINGS = 'Filter_Settings',
	FORECASTED_RESULTS = 'Forecasted_Results',
	SCHEDULE = 'Schedule',
	BrandSafetyTargetingConfig = 'BrandSafetyTargetingConfig',
}

enum AudienceInputKeys {
	SEGMENT = 'segment',
	LIST = 'list',
	CAMPAIGN = 'campaign',
	AUDIENCE = 'audience',
	ATTRIBUTE_GROUP = 'attributeGroup',
	CAMPAIGN_GROUP = 'campaignGroup',
	PROSPECT_OWNER_ID = 'prospectOwnerId',
}

enum DataTypesKeys {
	FORECASTED_RESULTS_DATA = 'forecastedResultsData',
	FILTER_SETTINGS_DATA = 'filterSettingsData',
	AUDIENCE_DATA = 'audienceData',
	CRON_SETTINGS = 'cronSettings',
	SCHEDULE_DATA = 'scheduleData',
}

enum ChannelConfigOperation {
	UPSERT = 'UPSERT',
	CREATE = 'CREATE',
	UPDATE = 'UPDATE',
}

enum CampaignStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DRAFT = 'DRAFT',
	ARCHIVED = 'ARCHIVED',
	PENDING = 'PENDING',
	IMPORTING = 'IMPORTING',
	DUPLICATING = 'DUPLICATING',
	FAILED = 'FAILED',
}

enum OperationKeys {
	ACCOUNTS_CREATE = 'ACCOUNTS_CREATE',
	ACCOUNTS_UPDATE = 'ACCOUNTS_UPDATE',
	CONTACTS_CREATE = 'CONTACTS_CREATE',
	CONTACTS_UPDATE = 'CONTACTS_UPDATE',
	LEADS_CREATE = 'LEADS_CREATE',
	LEADS_UPDATE = 'LEADS_UPDATE',
}

enum AudienceSource {
	LATTICE_ENGINES = 'LATTICEENGINES',
}

enum LookupIds {
	ADS = 'ADS',
	DSP = 'DSP',
	FILE_SYSTEM = 'FILE_SYSTEM',
	MAP = 'MAP',
}

enum LaunchBaseTypes {
	TASK = 'TASK',
	AUDIENCE = 'AUDIENCE',
}

enum StaticAudienceType {
	COMPANY = 'COMPANY',
	USER = 'USER',
}

export {
	Connections,
	Tiles,
	AudienceInputKeys,
	DataTypesKeys,
	ChannelConfigOperation,
	CampaignStatus,
	OperationKeys,
	AudienceSource,
	LookupIds,
	LaunchBaseTypes,
	StaticAudienceType,
};
